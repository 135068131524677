@use '../../scss/' as *;

.entire-card {
    @include flex(center, center, row);
    width: 100%;
    margin-bottom: 50px;
    padding-bottom: 20px;

    .cover-side {
        width: 55%;
        height: $project-card-height;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        
        &:hover {
           background-size: contain; 
        }
    
        &:hover + .project-card {
            background-color: $primary-color;
            margin-left: 0px;
            height: $project-card-height;
            // .project-card-left {
            //     @include flex(flex-start, space-between, column);
            // }
    
            margin-left: -5px;
            border-radius: 0px 10px 10px 0px;
            
            .project-card-left {  
                height: 100%;
                margin-left: 0%;
            }
    
            .project-card-right {
                display: none;
                // border-radius: 50%;
            }
    
            .project-card-top {
                margin-bottom: 15%;
            }
        }
    }

    .project-card {
        width: 50%;
        // height: 100%;
        background-color: $white;
        @include flex(center, space-between, row);
        padding: 15px;
        border-radius: 10px;
        box-shadow: rgba(138, 48, 48, 0.2) 0px 2px 8px 0px;
        margin-left: -20%;
    
        .project-card-left {
            margin-right: 5%;
    
            h2 {
                font-size: 30px;
                margin-bottom: 10px;
            }
    
            .project-card-tech-list {
                margin-top: 10%;
                margin-bottom: 10px;
                color: $black;
                @include flex(center, flex-start, row);
    
                .project-card-tech-item {
                    @include flex(center, center, row);
                    margin-left: 5px;
                    margin-right: 10px;
                }
            }
        }
    
        .project-card-right {
            img {
                width: 150px;
                height: 200px;
                border-radius: 10px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
            
        }
    
        // transition-property: ;
        transition-duration: 0.2s;
        // transition-timing-function: unset;

        // :hover + .cover-side {
        //     background-size: contain; 
        // }
    
        &:hover {
            // cursor: pointer;
            // .project-card-left{
            //     @include flex(flex-start, space-between, column);
            // }
            background-color: $primary-color;
            margin-left: -5px;
            height: $project-card-height;
            border-radius: 0px 10px 10px 0px;
    
            .project-card-left { 
                height: 100%;
            }
            .project-card-right {
                display: none;
                // border-radius: 50%;
            }
    
            .project-card-top {
                margin-bottom: 15%;
            }
    
            
        }
    
        .project-card-top {
            // margin-top: -15px;
            // margin-left: -15px;
            background-color: rgb(246, 216, 46);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            color: $black;
            width:200px;
            height: auto;
            text-align: center;
            padding: 10px;
            border-radius: 30px 0px 30px 0px;
            margin-bottom: 10px;
            
        }
    
        .project-button {
            background-color: rgb(246, 216, 46);
            padding: 3px;
            margin-right: 2%;
        }
    
    
       
    }
   
}


@media (max-width: $mobile-width) {
    .entire-card {
        .cover-side {
            display: none;
        }
    
        .project-card {
            // background-color: red;
            width: 100%;
            height: auto;
            margin-left: 1%;

            &:hover {
                font-size: 15px;
                margin-left: 1%;
            }
        }
    }
    
}





