@use './scss/' as *;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 100%;

    @include mobile {
        font-size: 60%;
    }
}

body {
    font-family: $font-family;
    font-weight: 400;
    line-height: 1.5;

    background-color: #3A3B3C;
    color: $text-color;
    overflow: hidden;
    // max-width: 1477px;

}

h1, h2, h3 {
    color: $black;
}

a {
    text-decoration: none;
    color: unset;
}

@media (hover: hover) {
    a:hover {
        color: $primary-color;
        cursor: pointer;
    }
}

.full-page {
    @include flex(flex-start,space-between, row);
}

.side-space {
    width: auto;
}

.page-wrapper {
    @include flex(flex-start, space-between, row);
    background-color: $body-bg;
    height: 100vh;
    max-width: $max-width;
    width: 75%;
    overflow-y: auto;
}

.project-list-group {
    color: $text-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    

    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        margin-bottom: 5px;

        li {
            padding-left: 10%;
            padding-right: 10%;
            margin: 2%;
            text-align: center;
            cursor: pointer;
            border-radius: 10px;

            .active {
                font-weight: bold;
                background-color: $primary-color;
            }

            &:hover {
                background-color: $primary-color;
            }
        }
    }
}

 ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

// .page-wrapper::-webkit-scrollbar {
//     display: none;
// }

.main {
    width: 75%;
    height: 100%;
    overflow: inherit;
}

.page-title {
    margin-top: 20px;
}

.small-screen-nav {
    display: none;
}

@media (max-width:$max-width) {
    .page-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .side-space {
    // width: auto;
    display: none;
}
}

@media (max-width: $tablet-width) {
    .main {
        width: 100%;
    }
}



// .side-bar-left {
//     hei
// }