@use '../../scss' as *;

.slider-wrapper {
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}


.header1{
    @include flex(center, center, row);
    width: 100%;
    background-image: url("../../assets/pictures/backgrounds/cardB.png");
    background-size: cover;
    

    .header1-left-div {
        width: 60%;
        padding: 4%;
        h1 {
            font-size: 50px;
            span {
                color: $primary-color;
            }
        }

        .typewriter-wrapper {
            font-size: inherit; // Ensures font size matches the parent
        }
        
        .typewriter-cursor {
            color: $primary-color; // Matches cursor to primary color
        }

        .header1-left-div-sub-header {
            p {
                color: $text-color;
            }
        }
        .header1-buttons-group { 
            margin-top: 100px
        }
        .header1-left-div-buttons {
            @include flex(center, flex-start, row);
            margin-top: 10%;
            .btn {
                @include flex(center, center, row);
                background-color: $primary-color;
                padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 16px;
                font-weight: 500;
                border-radius: 10px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                margin-right: 20px;
                span {
                    margin-right: 10px;
                }

                .cv {
                   display: none; 
                }
            }

            .btn:hover {
                background-color: $white;
                color: $primary-color;
                border-color: $primary-color;
                border-width: 1px;
            }
        }
        
    }

    .header1-right-div {
        width: 350px;
        height: 350px;
        border-radius: 100%;
        border-width: 1px;
        border-color: $black;
        background-image: url("../../assets/pictures/personal/profile3.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        // border-style: solid;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}

@media (max-width: $max-width) {
    .header1-right-div {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: $mobile-width) {
    .header1 {
        .header1-left-div {
            .header1-buttons-group {
                .header1-left-div-buttons {
                    @include flex(center, center, row);
                    width: 100%;
                    align-items: center;
                    justify-content: center;
        
                    .btn {
                    
                        i {
                            display: none;
                        }
        
                        .resume {
                            display: none;
                        }
        
                        .cv {
                            display: block;
                        }
                    }

                }
            }
            
        }

        .header1-right-div {
            display: none;
        }
    }
    

    .header1-left-div { 
        width: 100%;
        text-align: center;
    }

    .header1-left-div-buttons {
       

       
    }

    

}