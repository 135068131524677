@use "../scss/" as *;

.route-wrapper {
    margin-bottom: 50px;
}

@media (max-width: $tablet-width) {
    .route-wrapper {
        margin-top: 80px;
    }
}

@media (max-width: $mobile-width) {
    .route-wrapper {
        margin-bottom: 100px;
        margin-top: 0px;
    }
}