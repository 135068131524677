@use "../../scss/"as *;

.side-bar-right {
    // position: fixed;
    // right: 0;
    position: relative;
    height: 100%;
    width: 6%;
    background-color: #FAFAFA;
    // align-self: center;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    transition: .2s ease;

    &:before {
        content: "";
    }
    &:after {
        content: "";
    }
    .nav-buttons {
        @include flex(center, center, column);  
        .btn {
            margin: 20px;
            padding: 1px;
        }

    }

}

@media (max-width: $tablet-width) {
    .side-bar-right {
        display: none;
    }
}
