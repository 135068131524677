$body-bg: #f0f0f6;
$primary-color: #FFB400;
$primary-color-2: rgba(255, 180, 0, 0.95);

$black: #242526;
$white: #FFFFFF;

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$border-radius: 30px;

$mobile-width: 661px;
$tablet-width: 1024px;
$max-width: 1623px;
$min-height:800px;

$profile-radius: 50%;

$text-color: #767676;



$font-family: "Montserrat",
sans-serif;

$project-card-height: 500px;