@use '../../scss'as *;

button {
    cursor: pointer;
    font-family: $font-family;
}

.btn {
    border: 4px solid transparent;
    color: $black;
    text-align: center;
    transition: box-shadow .3s ease;
    position: relative;
}

.btn:hover {
    // box-shadow: 0px 0px 7px 15px #ff00004d;
}

.btn-outline {
    border: 3px solid $white;
    background-color: transparent;
    color: $white;
    box-shadow: unset;
    transition: color .3s ease, background-color .3s ease;
}

.btn-outline:hover {
    box-shadow: unset;
    color: $primary-color;
    background-color: $white;
}

.small {
    border-width: 2px;
    padding: .2rem 1.5rem;
    font-size: 1rem;
}

.medium {
    font-size: 2rem;

}

.btn-social-icon {
    // width: 200px;
    // display: flex;
    border-radius: 50%;
    // font-size: 100%;
    background-color: $primary-color;
    transition: .1s ease;
    border-width: 1px;
    
    
    i{
        padding: 4px;
        align-self: center;
    }

    
}

.btn-social-icon:hover {
    color: $primary-color;
    background-color: transparent;
    border-color: $black;
    // border-width: 0.5px;
}

.btn-nav-icon {
    border-radius: 50%;
    font-size: 1.5rem;
    transition: .1s ease;
    padding: 10px;
    
    
    i{
        padding: 4px;
    }

    
}

.btn-nav-icon:hover {
    color: $black;
    background-color: $primary-color;
    border-color: $black;
    border-width: 1px;
    
}

.btn-nav-icon.active {
    color: $black;
    background-color: $primary-color;
}

.nav-button-title {
    margin-bottom: 50px;
    font-size: 80%;
    font-weight: 600;
}

@media (max-width: 1289px) {  
    .nav-button-title {
        display: none;
    }
}