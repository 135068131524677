@use "../../scss/"as *;

.small-nav-wrapper {
    display: none;
    position: fixed;
    width: 100%;
    transition: .1s ease;
    background-color: #3A3B3C;
    color: $white;
    // z-index: 1002;
    box-shadow: 0 2px 10px -2px $primary-color;  
   

    span{
        font-size: 15px;
        cursor: pointer;
        padding: 20px;

        :hover {
           color: $primary-color 
        }   
    }

    i.small-nav-icon {
        display: none;
    }

}

.active {
    background-color: $primary-color;
    color: black;
}


.btn-cv-small-top-nav {       
    .btn {
        @include flex(center, center, row);
        background-color: $primary-color;
        width: 100%;
            // margin-bottom: 10px;
        height: 40px;
        font-weight: 700;
        font-size: 14px;
        padding: 20px;
        border-radius: 15px;
            // font-size: 85%;
        span {
            margin-right: 10%;
                // font-size: 100px;
        }

            
    }

    :hover {
        background-color: $white;
        color: $primary-color;
        border-color: $primary-color;
        border-width: 1px;
    }
        
}


@media (max-width: $tablet-width) {
    .small-nav-wrapper {
        @include flex(center, space-around, row);
        padding: 10px;
        z-index: 1000;
        // top: 0;        
        
    }
    .small-nav-wrapper span :hover {
        color:black
    }
}

@media (max-width: $mobile-width) {
    .small-nav-wrapper {
        width: 100%;
        z-index: 1000;
        position: fixed;
        bottom: 0;
        margin-bottom: 0px;
        box-shadow: none;

        span {
            border-radius: 50%;
            font-size: 20px;
        }
        .small-nav-text {
            display: none;
            font-size: 50px;
        }

        i.small-nav-icon {
            display: block;
            color: white;
        }

        i.small-nav-icon.active {
            color: black;
        }

        .btn-cv-small-top-nav {
            .btn {

                span {
                    display: none;
                }
            }
        }
    }
}

