@use './../../scss' as *;

.about-wrapper {
   margin-left: 5%;
   margin-right: 5%;
   // margin-bottom: 100px;
   // @include flex(center, space-around, column);

   .about-info {
      h2 {
         margin-bottom: 20px;
      }

      .about-page-text {
         font-size: 16px;
      }
   }

   .what-i-do {
      margin-top: 60px;
      margin-bottom: 60px;

      h2 {
         margin-bottom: 20px;
      }
      
      .what-i-do-list {
         @include flex(center, space-between, row);
         // display: grid;
         // grid-template-columns: repeat(3, 1fr);
         // gap: 8% 3%;

         .what-i-do-card {
            @include flex(center, space-around, column);
            margin: 10px;
            width: 100%;
            height: 458px ;
            background-color: $white;
            padding: 5%;
            border-radius: 10px;
            font-size: 16px;

            i {
               padding-top: 22px;
               color: $primary-color;
               padding-bottom: 22px;
               font-size: 80px;
            }

            p {
               padding-top: 22px;
               text-align: center;
               color: $text-color;
               padding-bottom: 22px;
            }
         }
      }


    
   }

   .skills-about {
      display: none;
   }
}

 

 

 @media (max-width: $tablet-width) {
   .about-wrapper {   
      
      .what-i-do {

         .what-i-do-list {
            @include flex(center, space-between, column);
            
            .what-i-do-card {
              height: 100%;
              width: 90%;
            }
         }
      }

      .skills-about {
         display: block;
      }

   }
      
 }

 @media (max-width: $mobile-width) {
   .about-wrapper {
      // margin-bottom: 300px;
      .what-i-do {

         .what-i-do-list {
            // grid-template-columns: none;
            color: white;

            .what-i-do-card {
               width: 100%;
            }
         }
      }
   }
   
 }