@use "../../scss/" as *;

.vertical-timeline-element--work {
    h4 {
        color: $text-color
    }

    .time-card-header {
        display: grid;
        grid-template-columns: 1fr 4fr;

        .time-card-header-logo {
            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .time-card-description {
        margin-top: 3%;
        ul {
            padding-left: 10px;
            padding-right: 10px;
            li {
                padding: 10px;
            }
        }
    }

    .technologies-used {
        margin-top: 10px;
    
        h5 {
            margin-bottom: 5px;
            font-size: 14px;
            color: black;
        }
    
        .technologies-icons {
            display: flex;
            gap: 10px;
            overflow-x: auto;
            padding: 5px 0;
    
            .technology-icon {
                font-size: 24px;
                color: $primary-color;
                flex: 0 0 auto; // Prevent shrinking
            }
    
            /* Hide scrollbar */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    
}