@use '../../scss/' as *;

.level-bar-wrapper {
    height: 5px;
    margin-top: 3px;
    width: 100%;
    border-color: $primary-color;
    border-style: solid;
    border-radius: 30px;
    border-width: 1px;
    // background-color: black;
    .level-bar {
        height: 100%;
        background-color: $primary-color;
        // color: green;
        // margin-top: 20px;
    }
}