@use '../../scss/' as *;

.skills-list-wrapper {
    margin-top: 1rem;
    overflow: hidden;

    h3 {}

    .skill-list {
        width: 100%;
        // height: 30%;

        .skill-item {
            margin-bottom: 20px;
            margin-top: 10px;

            .skill-item-header {
                @include flex(space-between, space-between, row);
                font-size: 15px;

                span {
                    font-weight: bolder;
                }
            }
        }
    }

    .skill-scroll {
        margin-top: 10px;
        font-size: 10px;
        font-weight: bold;
        color: $text-color;
        display: none;
    }
}

@media (max-width: $tablet-width) {
    .skills-list-wrapper {
        width: 100%;

        h3 {
            display: none;
        }

        .skill-list {
            .skill-item {
                .skill-item-header {
                    span {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media (min-height: $min-height) {
    .skills-list-wrapper {
        .skill-list {
            height: 300px;
            height: 100%;
            overflow-x: scroll;
            overflow-y: scroll;
        }

        .skill-scroll {
            display: block;
        }
    }
}