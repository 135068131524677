// @mixin flex($align-item, $justify-content) {
//     display: flex;
//     align-items: $align-item;
//     justify-content: $justify-content;
// }

@mixin flex($align-item, $justify-content, $direction) {
    display: flex;
    flex-direction: $direction;
    align-items: $align-item;
    justify-content: $justify-content;
}