@use "../../scss/" as *;

.side-bar-left {
    @include flex(center, flex-start, column);
    height: 100%;
    width: 15%;
    background-color: $white;
    // position: fixed;
    position: relative;
    // padding-top: 0.5%;

    .profile-section {
        @include flex(center, flex-start, column);
        padding-top: 10px;
        .profile-picture-wrapper {
            height: 100px;
            width: 100px;
            border-radius: $profile-radius;
            background-image: url("../../assets/pictures/personal/profile.JPG");
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .profile-info-wrapper {
            @include flex(center, center, column);
            padding-top: 15px;
            text-align: center;

            h3 {
                padding-bottom: 5px;
            }

            p {
                padding-bottom: 15px;
            }

            .profile-socials {
                .btn {
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                }
            }
        }


    }
   

    hr {
        width: 75%;
        color: $black;
        border: 1px solid #F0F0F6;
        margin-top: 1.5rem;
    }

    .overview {
        // overflow: hidden;
        width: 75%;
        text-align: center;
        height: 55%;
        overflow: scroll;
    }

    .skill-scroll {
        margin-top: 10px;
        font-size: 10px;
        font-weight: bold;
        color: $text-color;
        // display: none;
    }





    .btn-cv {
        position: absolute;
        bottom: 0;
        width: 85%;
        .btn {
            @include flex(center, center, row);
            background-color: $primary-color;
            width: 100%;
            margin-bottom: 10px;
            height: 40px;
            font-weight: 700;
            font-size: 14px;
            // font-size: 85%;
            span {
                margin-right: 10%;
            }
        }

        :hover {
            background-color: $white;
            color: $primary-color;
            border-color: $primary-color;
            border-width: 1px;
        }
        
    }
}

@media (max-width: $tablet-width) {
    .side-bar-left {
        display: none;
    }
}

// @media (min-height: $max-height) {
//     .side-bar-left {
//         .profile-section {
//             .profile-picture-wrapper {}
//         }

//         .overview {
//             // overflow: hidden;
//             // .skill-scroll {
//             //     display: block;
//             // }
//         }
//     }
// }

